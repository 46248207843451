<template>
  <div>
    <a-table
        :columns="configPayload.columns"
        :data-source="table_list"
        :rowKey="(record, index) => index"
        :pagination="false"
        :loading="table_loading"
        :scroll="scrollSetting"
    >
      <template
          v-for="column in configPayload.columns"
          :slot="column.scopedSlots ? column.scopedSlots.customRender : ''"
          slot-scope="text, record"
      >
        <slot
            :name="column.scopedSlots ? column.scopedSlots.customRender : ''"
            v-bind="record"
        ></slot>
      </template>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
        v-if="noPagination"
        :currentPage="pagination.current"
        :pageSize="pagination.page_size"
        :total="pagination.total"
        @onChange="onChange"
        @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>
<script>
export default {
  props: {
    configPayload: {
      type: Object,
      required: true,
    },
    selectValue: {
      type: Object,
      default: undefined,
    },
    // 初始时是否请求列表
    initData: {
      type: Boolean,
      default: true,
    },
    regroupPayload: {
      type: Boolean,
      default: false,
    },
    // 是否为分页类型
    noPagination: {
      type: Boolean,
      default: true,
    },
    // table宽度 滚动
    scrollSetting: {
      type: Object,
      default: function() {
        return {x: 1200};
      },
    },
  },
  data() {
    return {
      table_list: [],
      table_loading: false,
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
    };
  },
  mounted() {
    if(this.initData) {
      this.getTableList(1, 10);
    }
  },

  methods: {
    //   获取table 列表信息
    getTableList(page, size) {
      let select_value = this.selectValue
          ? JSON.parse(JSON.stringify(this.selectValue))
          : this.selectValue;
      let payload = {
        page,
        size,
        ...select_value,
      };
      if(this.regroupPayload) {
        payload = {
          type: select_value.type,
          params: {
            page,
            size,
            ...select_value.params,
          },
        };
      }
      if(!this.noPagination) {
        delete payload.page;
        delete payload.size;
      }
      this.table_loading = true;
      this.configPayload
          .http_api(payload)
          .then((res) => {
            if(res.code == 200) {
              if(this.noPagination) {
                const {list, page, size, total} = res.data;
                this.table_list = list;

              this.pagination = {
                current: page,
                page_size: size,
                total: total,
              };
            } else {
              this.table_list = res.data;
            }
            this.$emit('getTableList',this.table_list)
          } else {
            this.$message.error(res.message||res.msg);
          }
        })
        .finally(() => {
          this.table_loading = false;
        });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, page_size) {
      this.getTableList(current, page_size);
    },
  },
};
</script>
