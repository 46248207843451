<template>
  <div class="btn-r">
    <a-button type="primary" class="btn-new" size="small" @click="goCreate">
      新建工单
    </a-button
    >
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="我的工单">
        <a-radio-group
          @change="onChangeRadio"
          default-value="deal"
          button-style="solid"
        >
          <a-radio-button
            v-for="item in radioCode"
            :key="item.value"
            :value="item.value"
          >
            {{ item.lebal }}
          </a-radio-button>
        </a-radio-group>
        <div class="pdt12">
          <cn-table
            :configPayload="configPayload"
            :selectValue="searchForm"
            :regroupPayload="regroupPayload"
            ref="cntable"
          >
            <div slot="status" slot-scope="text">
              {{ disposeStatus(text.status) }}
            </div>
            <div slot="action" slot-scope="text">
              <a-button type="link" @click="gotRouter(text.id)"
              >查看详情
              </a-button
              >
            </div>
            <div slot="servicePhase" slot-scope="text">
              <span>{{ getServicePhase(text) }}</span>
            </div>
          </cn-table>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="全部工单" force-render>
        <a-alert :message="totalMessage" :type="alertType" />
        <div class="pdt12">
          <cn-table
            :configPayload="configAllPayload"
            :selectValue="searchAllForm"
            ref="cnAllTable"
          >
            <div slot="action" slot-scope="text">
              <a-button type="link" @click="gotRouter(text.id)"
              >查看详情
              </a-button
              >
            </div>
            <div slot="status" slot-scope="text">
              {{ disposeStatus(text.status) }}
            </div>
            <div slot="servicePhase" slot-scope="text">
              <span>{{ getServicePhase(text) }}</span>
            </div>
          </cn-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import CnTable from '@/components/CnTable';
import _https from '@/api/workOrder';

const configPayload = {
  columns: [
    {
      title: '工单编号',
      dataIndex: 'orderCode',
      align: 'center',
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '发起人',
      dataIndex: 'creatorName',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'department',
      align: 'center',
    },
    {
      title: '关联客户',
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: '期望完成时间',
      dataIndex: 'expectedFinishDate',
      align: 'center',
    },
    {
      title: '数据服务阶段',
      scopedSlots: { customRender: 'servicePhase' },
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      align: 'center',
    },
    {
      title: '更新时间',
      dataIndex: 'mtime',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ],
  http_api: _https.getMyWorkOrder,
};
const configAllPayload = {
  columns: [
    {
      title: '工单编号',
      dataIndex: 'orderCode',
      align: 'center',
    },
    {
      title: '标题',
      dataIndex: 'title',
      align: 'center',
    },
    {
      title: '申请人',
      dataIndex: 'creatorName',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'department',
      align: 'center',
    },
    {
      title: '关联客户',
      dataIndex: 'customerName',
      align: 'center',
    },
    {
      title: '数据服务阶段',
      scopedSlots: { customRender: 'servicePhase' },
      align: 'center',
    },
    {
      title: '期望完成时间',
      dataIndex: 'expectedFinishDate',
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      align: 'center',
    },
    {
      title: '更新时间',
      dataIndex: 'mtime',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '操作',
      dataIndex: 'id',
      fixed: 'right',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ],
  http_api: _https.allWorkOrder,
};
export default {
  data() {
    return {
      radioCode: [
        {
          value: 'deal',
          lebal: '待我处理',
        },
        {
          value: 'myself',
          lebal: '我创建的',
        },
        {
          value: 'join',
          lebal: '我参与的',
        },
      ],
      configPayload,
      searchForm: {
        type: 'deal',
        params: {},
      },
      regroupPayload: true,
      configAllPayload,
      searchAllForm: {},
      totalMessage: '共0个工单，其中0个处理中、0个已完成、0个已取消、0个已拒绝',
      alertType: 'success',
      servicePhaseList: [],
    };
  },
  components: {
    CnTable,
  },
  mounted() {
    this.allWorkOrderTotal();
    this.getMyWordOrder();
    this.getServicePhaseList();
  },
  methods: {
    getServicePhaseList() {
      _https.getServicePhaseList().then((res) => {
        if (res.code == 200) {
          this.servicePhaseList = res.data || [];
        } else {
          this.servicePhaseList = [];
          this.$message.error(res.message || '获取数据失败');
        }
      });
    },
    onChangeRadio(e) {
      this.searchForm.type = e.target.value;
      this.$refs.cntable.getTableList(1, 10);
    },
    gotRouter(id) {
      this.$router.push({
        path: '/workOrder/detail',
        query: {
          id,
        },
      });
    },
    disposeStatus(key) {
      let str = '';
      switch (key) {
        case 1:
          str = '处理中';
          break;
        case 2:
          str = '已完成';
          break;
        case 3:
          str = '已取消';
          break;
        case 4:
          str = '已拒绝';
          break;
        default:
          break;
      }
      return str;
    },
    getMyWordOrder() {
      _https.getMyWordOrder().then((res) => {
        if (res.code == 200) {
          const { dealCount, joinCount, myselfCount } = res.data;
          this.radioCode = [
            {
              value: 'deal',
              lebal: `待我处理(${ dealCount })`,
            },
            {
              value: 'myself',
              lebal: `我创建的(${ myselfCount })`,
            },
            {
              value: 'join',
              lebal: `我参与的(${ joinCount })`,
            },
          ];
        }
      });
    },
    allWorkOrderTotal() {
      _https.allWorkOrderTotal().then((res) => {
        if (res.code == 200) {
          const { total, process, alreadyFinally, cancel, deny } = res.data;
          this.alertType = 'success';
          this.totalMessage = `共${ total }个工单，其中${ process }个处理中、${ alreadyFinally }个已完成、${ cancel }个已取消、${ deny }个已拒绝`;
        } else {
          this.alertType = 'error';
          this.$message.error(res.message);
        }
      });
    },
    goCreate() {
      this.$router.push({
        path: '/workOrder/created',
      });
    },
    getServicePhase(v) {
      if (v.servicePhase) {
        let item = this.servicePhaseList.find(item => item.type == v.servicePhase);
        return item?.desc || '-';
      } else {
        return '-';
      }

    },
  },
};
</script>
<style lang="scss" scoped>
.pdt12 {
  padding-top: 12px;
}

.btn-r {
  position: relative;

  .btn-new {
    z-index: 999;
    position: absolute;
    right: 24px;
    top: 36px;
  }
}
</style>
